.buyLimit{
    width: fit-content;
    margin: auto;
    margin-bottom: 2rem;
}

.overlay_show_two{
        min-width: 100px;
        max-width: 500px;
        min-height: 100px;
        background-color: #fff;
        border-radius: 12px;
        padding: 5px 20px 20px;
    }
    @media(max-width:600px){
        .overlay_show_two{
            width: 352px;
            min-height: 550px;
            max-height: 660px !important;
            overflow: hidden;
        }
    }
.buyLimit .buyLimit_btn{
    background-color: var(--color-success);
    color: #fff;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 700;
    width: 330px;
    height: 55px;
    margin-bottom: 2rem;
    cursor: pointer;
}
 .overlay_show_two h2{
    font-size: 1.2rem !important;
    font-weight: 700;
    position: relative;
    text-align: center;
}
.overlay_show_two h2::after{
    position: absolute;
    content: '';
    width: 176px;
    height: 3px;
    background-color: var(--color-success);
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
}
.overlay_show_two form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2rem;
}
.overlay_show_two form .limitInput{
    width: 300px;
    height: 38px;
    border: none;
    outline: none;
    background-color: #E8E8E8;
    padding: 25px 20px;
    border-radius: 10px;
}
.overlay_show_two textarea.limitInput{
    height: 180px;
    padding-top: 10px;
    resize: none;
}
.buylimit_submit{
    background-color: var(--color-success);
    border: none;
    outline: none;
    width: 256px;
    height: 55px;
    border-radius: 10px;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;

}
.error{
    border: 1px solid #FF0000 !important;
}
.error_msg{
    color: red;
    margin-top: -24px;
    text-align: right;
}
.successOverLay{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.successOverLay h2{
    font-size: 20px;
    font-weight: 700;
}
.successOverLay h2::after{
    display: none;
}
.success_overlayImg{
    width: 300px;
    height: 300px;
}
.success_overlayImg img{
    width: 100%;
    height: 100%;
}