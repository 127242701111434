.product-images-slider .swiper-slide{
        padding-top: 100%;
        overflow: hidden;
        position: relative;
        background-color: #fff;
        border-radius: 20px;
        margin-bottom: 20px;    
        transition: transform 0.3s ease;
} 
.product-images-slider .swiper-slide-active {
    transform: scale(1.1);
    z-index: 1;
  }
  
  .product-images-slider .swiper-slide-next,
  .product-images-slider .swiper-slide-prev{
    transform: scale(0.9) !important;
    z-index: 0 !important;
  }
  .product-images-slider img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 1/1;
    padding: 15px 10px;

}
.offerProduct{
  position: absolute;
  top: 24px;
  left: 0px;
  width: 80px;
  height: 24px;
  z-index: 1000;
  transform: rotate(270deg);
  background-color: var(--color-success);
  color: #fff;
  border-radius: 5px 0 0 5px;
}
.offerProduct p{
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.product-images-slider-thumbs .swiper-slide{
        cursor: pointer;
        border: 1px solid transparent;
        padding: 5px;
        background-color: #fff;
        border-radius: 10px;
        width: 50px !important;
        margin: 0 auto !important;
    }
        .product-images-slider-thumbs .swiper-slide-thumb-active{
            border-color: #2A6EF4;
            box-shadow: none;
            width: 55px !important;
        }

        .product-images-slider-thumbs-wrapper {
        width: 100%;
        padding-top: 100%;
        overflow: hidden;
        position: relative;
    }
    .product-images-slider-thumbs-wrapper img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            object-fit: contain;
            aspect-ratio: 1/1;
            box-shadow: 0px 0px 10px 0px #00000040 !important;

        }
