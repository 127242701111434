.details_list .detailsUlList{
    padding: 30px;
}
.detailsUlList ul li{
    position: relative;
    right: 10px;
    color: #000 !important;
}
.detailsUlList ul li i{
    font-size: 22px;
    font-weight: 500;
}
.detailsUlList ul li::before{
    position: absolute;
   content: "\f058";
   color: #000;
   background: transparent;
   font: normal normal normal 22px/1 FontAwesome;
   right: -22px;
}
.detailsUlList ul li{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px !important;
}

.details_list .headLimit{
    margin: 0 12px 0px auto !important;
  }
.details_list .headLimit::after{
    bottom: -5px;
  }
@media(min-width:994px){
    .details_list .detailsUlList{
        padding-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .details_list .headbtn_1{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0 !important;
    }
    .details_list .headbtn_1::after{
        left: 50% !important;
        right: 44%;
        transform: translateX(-50%) !important;
    }
}

