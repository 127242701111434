.limitOffer{
    padding: 2rem 0 .5rem 0;
    overflow: hidden;
}
.limitOffer .headLimit1{
    margin-right: 200px !important;
}
.limitOffer .headLimit{
    margin: 0 12px 0px auto !important;
}

.limitOffer .headLimit::after{
    bottom: -5px;
  }

.limitOfferSection{
    background-color: #E7E7E7;
    padding: 2rem 0 1rem 0;
    margin-top: .7rem;
}
.countDownContent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    padding: 0 20px;
}
.countDownContent .timeNum{
    background-color: #2ECA8B33;
    font-size: 2.5rem;
    color: #13144D;
    font-weight: 700;
    border-radius: 10px;
    width: 70px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.timelabel{
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
}