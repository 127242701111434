.footer{
    background: #000000;
    border-top: 2px solid var(--color-success);
    border-radius: 20px 20px 0 0;
    width: 100%;
    min-height: 221px;
    margin: 60px 0 0 0;
}
.linksFooter{
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid var(--color-success);
    padding: 35px 0;
}
.linksFooter a{
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    transition: all .3s;
}
.linksFooter a:hover{
    color: var(--color-success);
}
.mail_footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 20px 0;
}
.mail_footer h3{
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}
.mail_footer p{
    font-size: 12px;
    font-weight: 700;
    color: #fff;
}
.mail_footer p > span{
    color: var(--color-success);
}

.mailIput{
    background-color: #fff;
    width: 255px;
    height: 45px;
    position: relative;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}
.mailIput form{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mailIput form input{
    padding: 10px;
    border: none;
    flex-basis: 100%;
    outline: none;
    font-size: 10px;
    font-weight: 500;
    background: transparent;
}
.mailIput form button{
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: var(--color-success);
    border: none;
    outline: none;
    border-radius: 10px;
    width: 150px;
    height: 39px;
}