.slickContainer{
  margin-bottom: -50px;
}
.slick-slide{
  margin: 0 5px;
  background-color: #fff;
  height: 150px;
}
.slickContainer .headLimit{
  margin: 0 12px 20px auto !important;
}
.slickContainer .headLimit::after{
  bottom: -5px;
}
.itemCard{
  width: 100% !important;
  padding: 10px 0 0 0;
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;

}
.imgContainer{
  display: flex;
  margin: 0 auto;
  width: 100% !important;
  height: 182px;
  overflow: hidden;
}
.img {
  aspect-ratio: 1/1;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .4s;
  width: 100%;
  max-width: 100%;
  height: 100%;
  }
  .headCard{
    width: 100%;
    padding: 10px;
  }
  @media(min-width:994px){
    .headCard{
      width: 100%;
      margin: 10px auto
    }
  }
  .headCard .title{
    font-size: 16px;
    font-weight: 500;
    text-align: right;
  }
  .slick_discount{
    background-color: var(--color-success);
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
  }
  .slick_discount h3{
    color: #fff;
    font-weight: 400;
    font-size: 16px;
  }
  .slick-arrow{
    display: none !important;
}
.slick_discount a{
  text-decoration: none !important;
}