.more_details{
    padding: 0 0 30px 0;
}

.more_details .headLimit{
    margin: 0 12px 0px auto !important;
  }
  .more_details .headLimit::after{
    bottom: -5px;
  }
.more_details_List{
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.more_details_List ul{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
}
.more_details_List ul li{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #E7E7E7;
    font-size: 1rem;
    font-weight: 500;
    padding: 5px 0!important;
}