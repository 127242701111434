.vQ{
    background-color: #E7E7E7;
    padding: 0 2rem;
}
.vQ .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;

}
.vQ .view , .request{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
}
.vQ .view i , .request i{
    font-weight: 500;
    font-size: 30px;
    margin-left: .4rem;
}
.vQ .view .span , .request .span{
    color: var(--color-success);
    padding-left: .2rem;
}