.card_content_btn,.card_content_btn2{
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: fit-content;
    background-color: #fff;
    gap: 5rem;
}
.card_content_btn2{
    margin-top: 1rem;
    gap: 1rem;
    margin: 1.5rem auto;
}
.btn_right{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: .5rem;
}
.card_content_btn p , .card_content_btn2 p{
    color: #000;
    font-size: 1rem;
    font-weight: 500;
}
.card_content_btn .store_logo , .card_content_btn2 .store_logo{
    width: 42px;
    margin: 0 .3rem;
}
.card_content_btn .store_logo img , .card_content_btn2 .store_logo img{
    width: 42px;
    height: 42px;
    max-width: 100%;

}
.card_content_btn button, .card_content_btn2 button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 10px 0 0 10px;
    background-color: var(--color-success);
    color: #fff;
    height: 55px;
    width: 120px;
    font-size: 16px;
    font-weight: 700;
    border: none;
    outline: none;
}

 .card_content_btn2 i{
    font-size: 24px !important;
    font-weight: 500;
}

.headbtn_1,.headbtn_2{
    font-size: 1.2rem;
    font-weight: 700;
    position: relative;
    margin-right: 12px !important;
}

.headbtn_1::after,.headbtn_2::after{
    position: absolute;
    content: '';
    bottom: -7px;
    right: 0;
    height: 2px;
    width: 90px;
    background-color: var(--color-success);
    font-weight: 700;
}
.headbtn_2::after{
    right: -11px;
}
@media(max-width:994px){
    .card_content_btn{
        display: none;
    }
    .headbtn_1{
        display: none;
    }
}
@media(min-width:994px){
    .card_content_btn2{
        display: none;
    }
    .headbtn_2{
        display: none;
    }
}
@media(max-width:767px){
    .card_content_btn2 p{
        font-size: .9rem;
        font-weight: 700;
    }
    .card_content_btn2 button{
        font-size: 16px;
    }
    .card_content_btn2 button i{
        font-size: 1.1rem;
    }
    .card_content_btn2{
        width: 350px;
        height: 55px !important;
        justify-content: space-between;
        font-size: .9rem;
        margin-top: 18px;
    }
}
.card_content_btn button{
    font-size: 16px !important;
}