.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 2px solid #ddd;
    border-radius: 0 0 20px 20px;
    z-index: 10000000;
}
.header .container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.header .logo{
    margin: 0 auto;
    width: 90px;
    height: 32px;
}
.logo img{
    width: 90px;
    height: 32px;
}
.menu i{
    font-size: 20px;
}