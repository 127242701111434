@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;700;800&display=swap');
:root{
    --color-primary: #26384A;
    --color-secondary: rgba(6, 116, 219,.2);
    --color-text: #FFFFFF;
    --color-text-light: #D9D9D9;
    --color-active: #0674D9;
    --color-success: #06A520;
    --bg-primary: #26384A;
    --bg-secondary: #F4F5F6;
  }
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  html{
    font-family: 'Tajawal', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    vertical-align: middle;
    color: var(--color-primary) !important;
    direction: rtl;
  }
  h1,h2,h3,h4,h5,h6,p,span,button{
    font-family: 'Tajawal', sans-serif !important;
    margin: 0 !important;
  }
  div{
    font-family: 'Tajawal', sans-serif;
  }
  body{
    min-height: 100vh;
    /* background-color: var(--bg-secondary) !important; */
    background-color: var(--bg-secondary) !important;
  }
  ::selection{
    background-color: var(--color-primary);
  }
  ul{
    padding: 0 !important;
    margin: 0 !important;
    list-style: none;
  }
  li{
    padding: 0 !important;
    margin: 0 !important;
  }