.card_details{
    margin-top: 1.5rem;
    padding-top: 60px;
}
.row_card{
    align-items: center;
    display: flex;
    justify-content: space-around;
}
@media(max-width:994px){
    .row_card{
        flex-direction: column;
    }
}
.card_content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
}
.card_content .card_content_header h2{
    color: var(--color-primary);
    font-weight: 700;
    font-size: 2.5rem;
}
.card_content .card_content_header span{
    font-size: 1rem;
    font-weight: 700;
    color: var(--color-active);
}

.card_content .card_content_rating{
    width: fit-content;
    border-radius: 100px;
    background-color: var(--color-success);
    color: var(--color-text);
    padding: 5px 8px;
    font-size: 1rem;
    text-align: center;
    font-weight: 700;
}
.card_content_rating > span{
    font-weight: 700;
}
.card_content_rating i{
    font-weight: 500;
}
@media(max-width:767px){
    .card_content .card_content_header span{
        font-size: .9rem;
        font-weight: 700;
        color: var(--color-active);
    }
    .card_content .card_content_rating{
        font-size: .8rem;
    }
    .card_content{
        gap: 0;
    }
}

.ImgSlider{
    width: 300px;
}
.ImgSlider img{
    max-width: 100%;
} 
@media(max-width:994px){
    .ImgSlider{
        margin-top: 20px;
        width: 55% !important;
        /* flex-basis: 100%; */
    }
}
@media(max-width:768px){
    .ImgSlider{
        margin-top: 20px;
        width: 75% !important;
    }
}
@media(max-width:600px){
    .row_card{
        display: block;
    }
    .card_content .card_content_header h2{
        font-size: 1.1rem;
    }
    .card_content{
        margin-right: 20px !important;   
    }
    .ImgSlider{
        margin-top: 20px;
        width: 80% !important;
        margin: 20px auto;
    }
}

.buy_btn_div{
    background-color: #E7E7E780;
}