.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    transition: all.5s;
    overflow: hidden;
    overflow-y: auto ;
    z-index: 10000000;
}
.overlay_show{
    min-width: 100px;
    max-width: 500px;
    min-height: 100px;
    background-color: #fff;
    border-radius: 12px;
    padding: 5px 10px;
}

@media(max-width:600px){
    .overlay_show{
        width: 352px;
        height: 300px;
        overflow: hidden;
    }
}
.closeIcon{
    cursor: pointer;
    width: fit-content;
    display: block;
    margin-right: auto !important;
}
.closeIcon i{
    font-size: 30px;
}
.modal_header{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}
.modal_header img{
    width: 80px;
    height: 80px;
    border-radius: 10px;
}
.modal_header h3{
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    color: #13144D;
}
.modal_body p{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}
.modal_body p:first-of-type{
    color: #FF1515;
    margin-bottom: 0 !important;
}
.modal_body p:last-of-type{
   font-size: 1.1rem;
   font-weight: 500;
}
.modal_body p:last-of-type .price_before{
   color: var(--color-success);
}
.modal_body p:last-of-type .price_after{
    color: #FF0000;
    position: relative;
}
.price_after::before{
    content: '';
    position: absolute;
    height: 40px;
    border-left: 1px solid #000;
    transform: rotate(60deg);
    left: 19px;
    top: -6px;
}
.price_after::after{
    content: '';
    position: absolute;
    height: 40px;
    border-left: 1px solid #000;
    transform: rotate(-60deg);
    left: 19px;
    top: -6px;
}
.modal_btn,.modal_btn2{
    border-radius: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 290px;
    margin: 0 auto;
}
.modal_btn {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='19' ry='19' stroke='%232ECA8BFF' stroke-width='4' stroke-dasharray='11' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
}
.modal_btn2 {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='19' ry='19' stroke='%232A6EF4FF' stroke-width='4' stroke-dasharray='11' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
}
.modal_btn input, .modal_btn2 input{
    width: 150px;
    height: 45px;
    text-align: center;
    border: none;
    font-size: 2.5rem;
    font-weight: 700;
    position: relative;
    border-radius: 0 20px 20px 0;
    outline: none;
}
input::selection{
    background-color: transparent;
}
.modal_btn button , .a a{
    width: 140px;
    height: 58px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    border-radius: 20px;
    position: relative;
    left: -5px;
}
.a a{
    background-color: #2A6EF4;
    text-decoration: none;
}
.modal_btn button{
    background-color: var(--color-success);
    border: none;  
}
.modal_btn button i{
    font-weight: 400;
    margin-left: .5rem;
}